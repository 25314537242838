.cookies {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 2rem;
    width: 100%;
    max-width: 600px;
    opacity: 1;
    background-color: var(--cookies_color);
    border-radius: 4px;
    color: #C1922D;
    transform: translateY(0);
    transition: all 0.5s ease;
    box-shadow: var(--cookies_box_shadow);
    opacity: 1;
    display: block;
    z-index: 102;
    @media (min-width: 768px) {
        bottom: 7rem;
        left: 50%;
        right: auto;
        transform: translate(-50%);
    }
    &.hidden {
        opacity: 0;
        transform: translateY(100%);
    }
    .cookies_policy {
        text-align: center;
        color: #004065;
        margin: 0 auto;
        width: 100%;
        max-width: 250px;
        p {
            font-size: 16px;
            width: 100%;
            a {
                text-decoration: underline;
                font-size: 16px;
                color: var(--btn_color);
            }
        }
        .cookies_consent_btn {
            width: 100%;
            margin-top: 1.5rem;
            padding: 1rem;
            background-color: var(--btn_color);
            color: white;
            cursor: pointer;
            font-size: 16px;
            border: none;
            outline: none;
            border-radius: 4px;
            font-family: "Roboto", sans-serif;
            box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
                rgba(0, 0, 0, 0.22) 0px 10px 10px;
            &:hover {
                background-color: var(--btn_color_hover);
            }
        }
    }
}
